@import "variables";
@import "highlight";
@import "languages";

@import "bootstrap/scss/bootstrap";
@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@4/distr/fira_code.css);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);

body{
  color: $dark;
}

@media (min-width: 1200px) {
  .container {
    width: 680px;
  }
}

header {
  margin-bottom: 2rem;
}

code {
  font-family: $fira-code;
}

.profile{
  font-family: $fira-code;
  margin: 1rem 0;
  h1{
    a {
      color: $dark;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
    text-align: center;
    small{
      font-style: italic;
      display: block;
      font-size: 1.3rem;
    }
  }
  .avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
  }
}

.profile-links {
  svg {
    width: 1.7rem;
    height: 1.7rem;
  }
  a {
    color: $dark;
    margin-left: 0.5rem;

  }
}

.posts {
  margin-bottom: 2rem;
}

.posts h2, .related h2{
  font-size: 1.6rem;
  font-family: $fira-code;
  text-transform: uppercase;
  margin-bottom: 2rem;
  border-bottom: 1px solid $dark;
}

.pagination {
  .pagination-item {
    padding: 1rem 2rem;
    border: 1px solid $link-color;
    border-radius: 3px;
  }

  a {
    &:hover {
      color: #fff;
      background-color: $link-color;
      text-decoration: none;
    }
  }
  span {
    color: $secondary;
    border: 1px solid $secondary !important;
  }
}

.post {
  .post-metadata{
    margin-bottom: 1rem;
  }

  h1{
    line-height: 1;
    color: $link-color;
    font-size: 2rem;
  }
  .post-date {
    font-style: italic;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  &:last-of-type{
    margin-bottom: 2rem;
  }

}

a.read-more {
  text-align: center;
  display: block;
  padding: 1rem 0;
  border: 1px solid $secondary;
  border-radius: 3px;
  &:hover {
    text-decoration: none;
  }
}


.image-wrapper {
  color: rgb(88, 96, 105);
  padding: 0.3rem;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(247, 247, 247);
  border-radius: 3px;
  margin-bottom: 1rem;
  .image-caption {
    text-align: center;
    font-style: italic;
    font-size: 0.9rem;
    margin: 0.2rem;
  }
}

.related {
  margin-top: 3rem;
  h3 {
    font-size: 1.3rem;
  }
}

blockquote {
  border-left: 3px solid $primary;
  padding: 2rem;
  font-style: italic;
  font-size: 1.1rem;
  background-color: $light;
  color: $dark;
  border-radius: 3px;
}

footer {
  &.footer {
    margin-top: 3rem;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

table.table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 2rem;
  * {
    position: relative;
  }
  td, th {
    padding-left: 8px;
  }
  thead tr {
    height: 60px;
    background: $dark;
  }
  tbody tr {
    height: 50px;
  }
  tbody tr:last-child {
    border: 0;
  }
  td, table th {
    text-align: left;
  }
  td.l, table th.l {
    text-align: right;
  }
  td.c, table th.c {
    text-align: center;
  }
  td.r, table th.r {
    text-align: center;
  }

  th{
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset;
  }

  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  tbody tr {
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
  }

  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

