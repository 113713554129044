.highlight {
	font-size: 1rem;
	background: #31343f;
  padding: 1rem;
	color: #dee2f7;
  margin-bottom: 1rem;
  pre {
    margin: 0;
  }
}

.highlight pre {
  color: #fff;
  background: #31343f
}

pre.lineno {
  text-align: right;
  color: #898b8f;
  padding-right: .5rem;
  padding-left: .5rem;
}


.highlight .hll {
	background: #31343f
}

.highlight .c {
	color: #63677e;
	font-style: italic
}

.highlight .err {
	color: #960050;
	background-color: #1e0010
}

.highlight .k {
	color: #e19ef5
}

.highlight .l {
	color: #a3eea0
}

.highlight .n {
	color: #dee2f7
}

.highlight .o {
	color: #dee2f7
}

.highlight .p {
	color: #dee2f7
}

.highlight .cm {
	color: #63677e;
	font-style: italic
}

.highlight .cp {
	color: #63677e;
	font-style: italic
}

.highlight .c1 {
	color: #63677e;
	font-style: italic
}

.highlight .cs {
	color: #63677e;
	font-style: italic
}

.highlight .ge {
	font-style: italic
}

.highlight .gs {
	font-weight: 700
}

.highlight .kc {
	color: #e19ef5
}

.highlight .kd {
	color: #e19ef5
}

.highlight .kn {
	color: #e19ef5
}

.highlight .kp {
	color: #e19ef5
}

.highlight .kr {
	color: #e19ef5
}

.highlight .kt {
	color: #e19ef5
}

.highlight .ld {
	color: #a3eea0
}

.highlight .m {
	color: #eddc96
}

.highlight .s {
	color: #a3eea0
}

.highlight .na {
	color: #eddc96
}

.highlight .nb {
	color: #fdce68
}

.highlight .nc {
	color: #fdce68
}

.highlight .no {
	color: #fdce68
}

.highlight .nd {
	color: #fdce68
}

.highlight .ni {
	color: #fdce68
}

.highlight .ne {
	color: #fdce68
}

.highlight .nf {
	color: #dee2f7
}

.highlight .nl {
	color: #fdce68
}

.highlight .nn {
	color: #dee2f7
}

.highlight .nx {
	color: #dee2f7
}

.highlight .py {
	color: #fdce68
}

.highlight .nt {
	color: #f9867b
}

.highlight .nv {
	color: #fdce68
}

.highlight .ow {
	font-weight: 700
}

.highlight .w {
	color: #f8f8f2
}

.highlight .mf {
	color: #eddc96
}

.highlight .mh {
	color: #eddc96
}

.highlight .mi {
	color: #eddc96
}

.highlight .mo {
	color: #eddc96
}

.highlight .sb {
	color: #a3eea0
}

.highlight .sc {
	color: #a3eea0
}

.highlight .sd {
	color: #a3eea0
}

.highlight .s2 {
	color: #a3eea0
}

.highlight .se {
	color: #a3eea0
}

.highlight .sh {
	color: #a3eea0
}

.highlight .si {
	color: #a3eea0
}

.highlight .sx {
	color: #a3eea0
}

.highlight .sr {
	color: #7be2f9
}

.highlight .s1 {
	color: #a3eea0
}

.highlight .ss {
	color: #7be2f9
}

.highlight .bp {
	color: #fdce68
}

.highlight .vc {
	color: #fdce68
}

.highlight .vg {
	color: #fdce68
}

.highlight .vi {
	color: #f9867b
}

.highlight .il {
	color: #eddc96
}

.highlight .gu {
	color: #75715e
}

.highlight .gd {
	color: #f92672
}

.highlight .gi {
	color: #a6e22e
}
